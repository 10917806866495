import URL from '../URL'
import HandleData from '../handleData'
import SaveGetData from "../../utils/saveGetData";


export default class Ajax {
  static ajax = (data, url = URL.url.url) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        header: {
          "Content-Type": "multipart/form-data",
        },
        body: HandleData(data),
      })
        .then((respones) => respones.json())
        .then((data) => {
          if (data.code.toString() === "305") {
            SaveGetData.removeData("token");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  static Uajax = (data, url = URL.url.v10Url) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        header: {
          "Content-Type": "	application/x-www-form-urlencoded",
        },
        body: HandleData(data),
      })
        .then((respones) => respones.json())
        .then((data) => {
          if (data.code.toString() === "305") {
            SaveGetData.removeData("token");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static Rajax = (data, url = URL.url.v9Url) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        header: {
          "Content-Type": "multipart/form-data",
        },
        body: HandleData(data),
      })
        .then((respones) => respones.json())
        .then((data) => {
          if (data.code.toString() === "305") {
            SaveGetData.removeData("token");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  static Sajax = (data, url = URL.url.v10Url) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        header: {
          "Content-Type": "multipart/form-data",
        },
        body: HandleData(data),
      })
        .then((respones) => respones.json())
        .then((data) => {
          if (data.code.toString() === "305") {
            SaveGetData.removeData("token");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  static esAjax = (data, url = URL.url.esUrl) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(data),
      })
        .then((respones) => respones.json())
        .then((data) => {
          if (data.code.toString() === "305") {
            SaveGetData.removeData("token");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  static definingAjax = ( url = URL.shareHotUrl, { method, data }, callback)=>{
    let fetchRequest = new Request(url, {
      method,
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8;",
    //   },
      data
    });
    fetch(fetchRequest).then(response => response.json())
    .then(res => {
      callback(res)
    });
  };
  static Ajax=(data,url=URL.exposureUrl)=>{
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: data
        })
            .then(respones => respones.json())
            .then(data => {
                if(data.code.toString()==='305'){
                    SaveGetData.removeData("token");
                }
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    })
};
  static mallAjax=(data, url = URL.url.v10Url)=>{
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                header: {
                    "Content-Type": "application/json",
                },
                body: HandleData(data),
            })
                .then((respones) => respones.json())
                .then((data) => {
                    if (data.code.toString() === "305") {
                        SaveGetData.removeData("token");
                    }
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
  static recommendAjax = (data, route) => {
    const url = `${URL.url.recommendUrl}${route}`;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify(data),
      })
        .then((respones) => respones.json())  
        .then((data) => {
            resolve(data);
        })
        .catch((error) => {
            // reject(error);
        });
    });
  }
}