import React, {Component} from 'react';
import './App.css';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import SaveGetData from "./utils/saveGetData";
import Ajax from "./utils/ajax";
import {LocaleProvider} from 'antd-mobile';
import LoginActivityMain from "./common/loginActivityMain";

// const vConsole = new VConsole({ theme: 'dark' });
// // call `console` methods as usual
// console.log('Hello world');

// // remove it when you finish debugging
// vConsole.destroy();
// // call `console` methods as usual
// console.log('Hello world');
class App extends Component {
    componentDidMount() {
        this.timer = setInterval(()=>{
            this.getProductId();
        },60000)
        document.addEventListener("visibilitychange", ()=>{
            if(document.hidden){
                this.getProductId();
            }
        });
    }
    componentWillUnmount() {
        if(this.timer){
            clearInterval(this.timer);
        }
        document.removeEventListener("visibilitychange", ()=>{
            if(document.hidden){
                this.getProductId();
            }
        });
    }
    getProductId = () =>{
        if(SaveGetData.getData("product_map") && JSON.parse(SaveGetData.getData("product_map")).length>0){
            let reviewer_id = parseInt(SaveGetData.getData("reviewer_id")? SaveGetData.getData("reviewer_id") : 0);
            let data = {
                product_map:JSON.parse(SaveGetData.getData("product_map")),
                reviewer_id:reviewer_id
            }
            Ajax.Ajax(JSON.stringify(data))
                .then(data=>{
                    if(data.code == 0){
                        SaveGetData.removeData("product_map");
                    }
                }
            )
        }
    }
    render() {
        return (
            <LocaleProvider locale={enUS}>
                <div className="App">
                    {this.props.children}
                    <LoginActivityMain></LoginActivityMain>
                </div>
            </LocaleProvider>
        );
    }
}

export default App;
