/*
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2021-08-30 14:40:39
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-08-30 15:07:21
 */
function evtBus() {
    const listeners = {}; // 保存已经注册的事件
    const isFunction = (fn) => fn && Object.prototype.toString.call(fn) === '[object Function]';
    const isString = (str) =>
      str && (typeof str === 'string' || Object.prototype.toString.call(str) === '[object String]');
    const isValid = (event, callback) => isString(event) && isFunction(callback);
  
    return {
      on(event, callback) {
        // 校验合法性
        if (isValid(event, callback)) {
          // 已经注册过该事件的话，将callback加入到该事件的回调数组中
          if (listeners[event] && !listeners[event].includes(callback))
            listeners[event].push(callback);
          else listeners[event] = [callback];
        } else {
          console.error('$evtBus.on() 参数不合法');
        }
      },
      emit(event, params) {
        console.log(listeners[event],'listeners[event]')
        if (isString(event) && listeners[event])
          listeners[event].forEach((cb) => {
            cb(params);
          });
      },
      remove(event, callback) {
        if (isValid(event, callback) && listeners[event]) {
          const index = listeners[event].indexOf(callback);
          if (index !== -1) listeners[event].splice(index, 1);
        }
      },
    };
  }
  
  export default new evtBus();
  
  