import React from 'react';
import ReactDOM from 'react-dom';
import Rooter from './router'
import Events from './utils/eventBus';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import ConfigStore from './redux/store/configStore'
const store = ConfigStore();
React.$Events = Events;

ReactDOM.render(
    <Provider store={store}>
        <Rooter />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
