import React, {Component} from 'react';
import { connect } from "react-redux";
import { Modal } from 'antd-mobile';
import './index.less';
import SaveGetData from "../../utils/saveGetData";
import Ajax from "../../utils/ajax";
class LoginActivityMain extends Component {
    state = {
        timer:null,
        isShow:false
    }
    componentDidMount() {
        this.state.timer = setInterval(()=>{
            console.log("计时结束");
            clearInterval(this.state.timer);
            // 抽奖弹窗显示
            let data = {
                action:"common/reg-lottery",
                is_wap:1,
            }
            if (!SaveGetData.getData("token"))
                data.token = SaveGetData.getData("token");
                Ajax.Sajax(data).then(res=>{
                    console.log("是否显示注册抽奖弹窗（大）",res)
                    if(res.code == 200){
                        const myString = window.location.href; 
                        const regex = /\/#\//; // 匹配'/#/'
                        const result = regex.test(myString);
                        if (result) {
                        const restOfString = myString.substring(myString.indexOf('/#/') + 3);
                            if (!restOfString  || restOfString === '?') {
                                if(res.data.guest_reg_lottery_modal){
                                    this.showModal()
                                }
                            }
                        }

                    }
            })
        },15000);//15000
        React.$Events.on('showModal',this.clickShowModal.bind(this));
    }
    componentWillUnmount() {
        if(this.state.timer){
            clearInterval(this.state.timer);
        }
        React.$Events.remove('showModal')
    }
    clickShowModal(data){
        console.log("eventBus监听点击事件",data)
        if(this.state.timer){
            clearInterval(this.state.timer);
        }
        this.setState({
            isShow:true,
        },()=>{
            this.expoRequest(1);// 弹窗曝光埋点
        });
    }
    // 弹窗曝光埋点
    expoRequest(value){
        let data={
            action:"common/reg-lottery-trace",
            is_wap:1,
            trace_type:value,//1：窗口曝光；2：点击跳转注册按钮
        }
        Ajax.Sajax(data).then(res=>{
            console.log("注册窗口曝光埋点",res)
        })
    }
    showModal = () => {
        console.log("显示当前路径",window.location.href)
        let href = window.location.href;
        let isOneDataShowThree = false;// 是否一天显示了三次
        let date = new Date();
        const dateData = date.getFullYear().toString() + (date.getMonth()+1).toString() +date.getDate().toString();
        let saveDataValue = SaveGetData.getData("OneDataShowThreeData")
        if(saveDataValue){
            const value = JSON.parse(saveDataValue)
            if(value.timer == dateData){
                if(parseInt(value.number) >= 3){
                    isOneDataShowThree = true;
                }else{
                    SaveGetData.saveData("OneDataShowThreeData", JSON.stringify({
                        timer:dateData,
                        number: parseInt(value.number) +1
                    })); 
                }
            }else{
                SaveGetData.saveData("OneDataShowThreeData", JSON.stringify({
                    timer:dateData,
                    number:1
                }));
            }
        }else{
            SaveGetData.saveData("OneDataShowThreeData", JSON.stringify({
                timer:dateData,
                number:1
            }));
        }
        if((href.indexOf('/login') < 0 && href.indexOf('/sign') < 0) && !isOneDataShowThree){

            if(!this.state.isShow){
                this.setState({
                    isShow:true,
                },()=>{
                    this.expoRequest(1);// 弹窗曝光埋点
                });
            }
        }
    }
    gotoLogin = () => {
        this.setState({
            isShow:false,
        },()=>{
            this.expoRequest(2);// 弹窗曝光埋点
            this.props.nowHistory.push("/sign?isLoginActivity="+true);
        });
    };
    render() {
        return (
            <div className='mainimg-outer'>
                <Modal
                    wrapClassName = "modal-outer"
                    visible={this.state.isShow}
                    transparent
                    maskClosable={false}
                >
                  
                    <div style={{width:'326px',height:'365px',position:'relative',margin:'auto'}}>
                        <img src="img/loginActivityMain.png"  style={{width:'326px',height:'365px'}}></img>
                        <div className='but-outer'>
                            <div className='join-but' onClick={this.gotoLogin.bind(this)}></div>
                        </div>
                    </div>
                    <div style={{position:"absolute",left:"95%",top:"0"}} onClick={()=>{this.setState({isShow:false})}}><img style={{width:'12px',height:'12px'}}  src="img/12_12_close.png" /></div> 
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        nowHistory: state.nowHistory
    };
};
export default connect(mapStateToProps)(LoginActivityMain);
