const productUrl = {
  url: "https://api.myvipon.com/v8/main/index",
  shareUrl: "https://www.myvipon.com/product/",
  reviewUrl: "https://www.myvipon.com/review/",
  shareHotUrl: "https://search.myvipon.com/",

  v9Url: "https://api.myvipon.com/v9/main/index",
  v10Url: "https://api.myvipon.com/v10/main/index",
  // esUrl: "https://search.myvipon.com/es/viponapp/search",
  esUrl: "https://search.myvipon.com/Api/v2/viponapp/search",
  recommendUrl: 'https://rec.myvipon.com'
};

const blueUrl = {
  url: "https://hktestapi.myvipon.com/v8/main/index",
  shareUrl: "https://pcblue.myvipon.com/product/",
  reviewUrl: "https://pcblue.myvipon.com/review/",
  shareHotUrl: "https://bluesearch.myvipon.com/",
  v9Url: "https://hktestapi.myvipon.com/v9/main/index",
  v10Url: "https://hktestapi.myvipon.com/v10/main/index",
  // esUrl: "https://bluesearch.myvipon.com/es/viponapp/search",
  esUrl: "https://bluesearch.myvipon.com/Api/v2/viponapp/search",
  recommendUrl: 'https://bluerec.myvipon.com'
};
const newblueUrl = {
  url: "https://hkapiblue.myvipon.com/v8/main/index",
  shareUrl: "https://pcblue.myvipon.com/product/",
  reviewUrl: "https://pcblue.myvipon.com/review/",
  shareHotUrl: "https://bluesearch.myvipon.com/",
  v9Url: "https://hkapiblue.myvipon.com/v9/main/index",

  v10Url: "https://hkapiblue.myvipon.com/v10/main/index",
  // esUrl: "https://bluesearch.myvipon.com/es/viponapp/search"
  esUrl: "https://bluesearch.myvipon.com/Api/v2/viponapp/search",
  recommendUrl: 'https://bluerec.myvipon.com'
}
const blueUrl2 = {
  url: "https://blueapi.myvipon.com/v8/main/index",
  shareUrl: "https://blue2.myvipon.com/product/",
  reviewUrl: "https://blue2.myvipon.com/review/",
  v9Url: "https://blueapi.myvipon.com/v9/main/index",
  v10Url: "https://blueapi.myvipon.com/v10/main/index",
  esUrl: "https://bluesearch.myvipon.com/es/viponapp/search",
  recommendUrl: 'https://bluerec.myvipon.com'
};
const betaUrl = {
  url: "https://betaapi.myvipon.com/v8/main/index",
  shareUrl: "https://testbeta.myvipon.com/product/",
  reviewUrl: "https://testbeta.myvipon.com/review/",
  shareHotUrl: "https://betasearch.myvipon.com/",
  v9Url: "https://betaapi.myvipon.com/v9/main/index",
  v10Url: "https://betaapi.myvipon.com/v10/main/index",
  // esUrl: "https://betasearch.myvipon.com/es/viponapp/search",
  esUrl: "https://betasearch.myvipon.com/Api/v2/viponapp/search",
  recommendUrl: 'https://betarec.myvipon.com'
};
const preProductUrl = {
  url: "https://api.vipon.com/v8/main/index",
  shareUrl: "https://www.vipon.com/product/",
  reviewUrl: "https://www.vipon.com/review/",
  v9Url: "https://api.vipon.com/v9/main/index",
  v10Url: "https://api.vipon.com/v10/main/index",
  recommendUrl: 'https://betarec.myvipon.com'
};

const zhuUrl = {
  url: "http://10.10.49.40:1030/v8/main/index",
  shareUrl: "https://blue.vipon.com/product/",
};
//开发环境
// const producExposureUrl ="";
// const exposureUrl =''

//生产环境
const producExposureUrl = "https://msapi.myvipon.com/v1/trace/push-goods-exposure-data-h5";
//测试环境
// const exposureUrl ='https://bluemsapi.myvipon.com/v1/trace/push-goods-exposure-data-h5'
// const exposureUrl = 'https://msapiblue.myvipon.com/v1/trace/push-goods-exposure-data-h5'
const exposureUrl = 'https://betamsapi.myvipon.com/v1/trace/push-goods-exposure-data-h5'

export default {
  // 生产环境
  url: productUrl,
  exposureUrl: producExposureUrl,

  // 预发布环境
  // url: betaUrl,
  // exposureUrl: exposureUrl,


  // // 第一套测试环境
  // url: blueUrl,
  // exposureUrl: exposureUrl,

  // 第二套测试环境
  // url: newblueUrl,
  // exposureUrl: exposureUrl,



  // url:preProductUrl,
  // url:zhuUrl,

  facebookId: '4501744066568675',
  googleId: '217282056431-1ntreks8anlb0p9j2b9tfgcb48scqe63.apps.googleusercontent.com'
}

// 生产环境
export const url9 = "https://api.myvipon.com/v9/main/index";
// 第一套测试环境
// export const url9 = 'https://hktestapi.myvipon.com/v9/main/index'
// 第二套测试环境
// export const url9 = 'https://hkapiblue.myvipon.com/v9/main/index'
// 预发布环境
// export const url9 = 'https://betaapi.myvipon.com/v9/main/index'
// export const url9 = 'https://bluemsapi.myvipon.com/v9/main/index'

// export const url10 = 'https://hkapiblue.myvipon.com/v10/main/index'
