/***
 * 
 *  数据处理
 * 
*/
import { type } from './../action'

const initialStore={
    open:false,
    nowHistory:'',
    selectedTab:'home',
    hidden:false,
    homeTab:0,
    dealDetails:'',
    goToCommentModal:0,
    is_read_request:1,
    is_read_collect:1,

}

export default (state = initialStore,action)=>{
    switch (action.type) {
        case type.is_read_request:
            return {
                ...state,
                is_read_request:action.data
            };
        break;
        case type.is_read_collect:
            return {
                ...state,
                is_read_collect:action.data
            };
        break;
        case type.open:
            return {
                ...state,
                open:action.data
            };
        break;
        case type.nowHistory:
            return {
                ...state,
                nowHistory:action.data
            };
        break;
        case type.selectedTab:
            return {
                ...state,
                selectedTab:action.data
            };
        break;
        case type.hidden:
            return {
                ...state,
                hidden:action.data
            };
        break;
        case type.homeTab:
            return {
                ...state,
                homeTab:action.data
            };
        break;
        case type.dealDetails:
            return {
                ...state,
                dealDetails:action.data
            };
        break;
        case type.goToCommentModal:
            return {
                ...state,
                goToCommentModal:action.data
            };
        break;
        case type.getCode:
            return {
                ...state,
                getCode:action.data
            };
        break;
        default:
            return {
                ...state,
            };
            break;
    }
}