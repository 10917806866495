/**
 *  action 类型
 * 
 * */ 

export const type={
    open:'open',
    nowHistory:'nowHistory',
    selectedTab:'selectedTab',
    hidden:'hidden',
    homeTab:'homeTab',
    dealDetails:'dealDetails',
    goToCommentModal:'goToCommentModal',
    getCode:"getCode",
    is_read_request:"is_read_request",
    is_read_collect:"is_read_collect",
}

export function switchMenu(type,data){
    return{
        type:type,
        data
    }
}









