export default class SaveGetData {
    static saveData(key,val){
        localStorage.setItem(key,val);
    }
    static getData(key){
        return localStorage.getItem(key)
    }
    static removeData(key){
        return localStorage.removeItem(key)
    }
}