import md5 from "md5";


const handleData = (data) => {

    if (data.brand !== "android" && data.brand !== "uniapp") data.is_wap = 1;
    console.log(data, 'data');

    const app = "@amzvipon#api*tracker931";
    let timestamp = Date.parse(new Date()).toString();
    let timestampTen = timestamp.substring(0, 10);
    let parameterArr = [];
    for (let key in data) {
        let obj = data[key];
        parameterArr.push(obj);
    }
    let parameterS = parameterArr.join("");
    let signBefore = app.concat(parameterS, timestampTen);
    data.timestamp = timestampTen;
    data.appsecret = md5(signBefore);
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}
export default handleData;